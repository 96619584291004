.container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1rem;
    padding-top: 0px;
    background-color: var(--background-color);
    overflow-y: auto;
}

.assignmentDetails {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.description {
    color: #555;
}

.students ul {
    list-style: none;
    padding: 0;
}

.students li {
    display: flex;
    align-items: center;
    margin: 5px 0;
}

.submissions {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.submissionList {
    list-style: none;
    padding: 0;
}

.submissionItem {
    border-bottom: 1px solid #eee;
    padding: 10px 0;
}

.submissionHeader {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.profilePicture {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.username {
    font-weight: bold;
}

.loading, .error {
    text-align: center;
    padding: 20px;
    font-size: 18px;
}

.error {
    color: #d32f2f;
}

.permissionError {
    padding: 20px;
    color: #ff0000;
    text-align: center;
    font-size: 18px;
}

.visualizeButton {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.visualizeButton:hover {
    background-color: #0056b3;
}