

.ck.ck-editor {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: auto;
    height: 100%;
}

.ck.ck-toolbar.ck-toolbar_grouping {
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 10;
}


.ck-dropdown__panel {
    max-height: 240px; /* Adjust as needed */
    overflow-y: auto;
}


/* CKEditor main area (new) */
.ck-editor__main {
    flex-grow: 1; /* Ensures it takes remaining space after toolbar */
    display: flex; /* Makes it a flex container for the editable area */
    flex-direction: column;
    height: 100%;
    padding-bottom: 30px;
    overflow-y: auto;
    overflow-x: auto;
}

/* Editable area */
.ck-content, .ck-editor__editable {
    max-width: 100%;
    min-width: 0;
    flex-grow: 1; /* Grows to fill .ck-editor__main */
    min-height: 100px; /* Optional: Prevents collapse when empty */
}

