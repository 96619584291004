@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    /* --background-color: linear-gradient(to bottom, rgba(0, 59, 59, 0.4), rgba(0, 102, 102, 0.7)); */
    --background-color: white;
    --navbar-color: white;
    --text-color-dark: #33312E;
    --text-color-light: white;
    --primary-color: #006666;
    --primary-color-hover: #005050;
    --secondary-color: #b2d8d8;
    --secondary-color-hover: #c1e0dc;
    --secondary-color-hover-notifications-gray: #f5f5f5;
    --light-hover-color: #f5f5f5;
    --accent-color: #d66d50;
    --accent-color-light: #dba392;
    --accent-color-hover: #dd5d3a;
    --input-placeholder: #7e7e7e;
    --font: "Helvetica";
    --navbar-height: 3.625rem;
    --tabsbar-height: 60px;
    --speed: 500ms;
    --toc-width: 16%;
    --alert-color: red;
    --canvas-topbar-height: 60px;
    --my-classrooms-header-height: 40px;
    --classroom-tabs-height: 50px;
    --ck-border-radius: 4px;
}

[data-theme="dark"] {
    --background-color: #333 !important;
    --text-color: #fff !important;
}

body,
html {
    color: var(--text-color-dark);
    font-family: var(--font);
    height: 100%;
    margin: 0;
}

[data-theme="dark"] body {
    filter: invert(0.925) hue-rotate(180deg) brightness(0.95) contrast(0.95);
}

[data-theme="dark"] img,
[data-theme="dark"] video {
    filter: invert(1) hue-rotate(180deg);
}

input:not([type="checkbox"]) {
    font-family: var(--font);
    border: 1px solid #ccc;
    border-radius: 10px;
    outline: none;
    transition: border-color 0.3s;
    color: var(--text-color-dark);
    height: 45px;
    font-size: 16px;
    padding: 10px;
}

input::placeholder {
    color: var(--input-placeholder);
    font-family: var(--font);
}

input:focus:not([type="checkbox"]) {
    border-color: var(--primary-color);
    box-shadow: 0 0 5px var(--primary-color);
}

button {
    padding: 0.75rem 1.5rem;
    background-color: var(--primary-color);
    color: var(--text-color-light);
    border-radius: 0.5rem;
    transition: background-color var(--speed) ease;
}



  







