.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modalContent {
    background-color: #fff;
    border-radius: 8px;
    width: 90%;
    max-width: 600px;
    padding: 1.5rem;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    height: 85vh;
}

.inputGroup {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
}

.inputGroup label {
    margin-bottom: 0.5rem;
}

.inputGroup input[type="date"],
.inputGroup input[type="radio"] {
    margin-top: 0.25rem;
}

.buttonGroup {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
}

.applyButton {
    padding: 0.5rem 1rem;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
}

.cancelButton {
    padding: 0.5rem 1rem;
    background-color: #6c757d;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
}
