.textContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1; /* Expands to fill available space */
    padding-top: 16px;
    padding-left: 2px;
    padding-right: 2px;
    box-sizing: border-box; /* Include padding in height/width */
    overflow-y: auto;
    align-items: center;
}

/* Subtabs Styles */
.subTabsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;            /* Space between bubbles */
    height: 3.75rem;      /* If you still want a fixed height */
    background-color: var(--background-color);
}

/* Single bubble tab */
.subTab {
    color: black;
    font-size: 17px;
    border: 1.5px solid black;
    border-radius: 20px;      /* Creates a pill/bubble shape */
    padding: 6px 20px;        /* Adds horizontal spacing */
    cursor: pointer;
    background-color: transparent;
    transition: background-color 0.3s ease, border-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Active bubble tab */
.activeSubTab {
    background-color: var(--primary-color);
    color: var(--text-color-light);
    border-color: var(--primary-color);
}

.iconItem {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin: 5px 0;
    background-color: #f5f5f5;
    color: var(--primary-color);
    cursor: pointer;
    border-radius: 50%;
    transition: background-color 0.3s, box-shadow 0.3s;
    user-select: none;
    border: 1px solid transparent;
    font-size: 24px;
}

.iconItem:hover,
.iconItem:focus {
    background-color: #d0d0d0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border-color: #bbb;
}


.titleInput {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    font-size: 1.125rem;
}




/* Media preview and media element styles */
.mediaPreview {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50px; /* Ensure the parent container doesn't collapse */
    margin-bottom: 16px;
}

.imagePreview {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.mediaElement {
    width: 100%;
    height: 40px; /* Explicit height for audio element */
    max-width: 100%;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

/* Button group styling for consistent layout */
.buttonGroup {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

@media (max-width: 480px) {
    .buttonGroup {
        flex-direction: column;
    }
}


.buttonGroup .buttonBase {
    flex: 1; /* Ensure buttons take equal space */
    padding: 12px; /* Increased padding for better touch targets */
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center;
    min-width: 120px; /* Optional: set a minimum width */
}

.buttonGroup {
    width: 80%;
}

/* Specific upload button styles */
.uploadButton {
    background-color: var(--primary-color);
}

.uploadButton:hover {
    background-color: var(--primary-color-hover);
}

/* Specific remove button styles */
.removeButton {
    background-color: #dc3545; /* Red color for remove */
}

.removeButton:hover {
    background-color: #c82333;
}

/* Focus styles for accessibility */
.buttonBase:focus {
    outline: 2px solid var(--primary-color);
    outline-offset: 2px;
}
.buttonBase:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.deleteButton, .previewButton {
    display: block;
    width: 100%;
    padding: 10px;
    color: white;
    font-size: 14px;
    font-weight: 600;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 50px;
}

.deleteButton {
    background-color: var(--accent-color);
}
.deleteButton:hover {
    background-color: var(--accent-color-hover);
}

.previewButton {
    background-color: var(--primary-color);
}
.previewButton:hover {
    background-color: var(--primary-color-hover);
}

