.container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1rem;
    padding-top: 0px;
    background-color: var(--background-color);
    overflow-y: auto;
}

.header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: sticky;
    top: 0;
    z-index: 8;
    background-color: var(--background-color);
    padding: 1rem;
    border-bottom: 1px solid #ddd;
}

.createButton {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 0.7rem 1.2rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
}

/* The filter button is pushed to the right if a teacher is present */
.filterButton {
    background-color: #28a745;
    color: #fff;
    border: none;
    padding: 0.7rem 1.2rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    margin-left: auto;
}

/* Filter details styling for a subtle, informative display */
.filterDetails {
    margin-left: 1rem;
    font-size: 0.9rem;
    color: #555;
}

/* A clear filter button styled in a red tone */
.clearFilterButton {
    background-color: #dc3545;
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    margin-left: 0.5rem;
}


.assignmentCard {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.assignmentHeader {
    display: flex;
    justify-content: space-between;
    font-size: 0.9rem;
    color: #666666;
    margin-bottom: 0.5rem;
}

.assignedDate,
.dueDate {
    margin-right: 0.5rem;
}

.assignmentTitle {
    font-size: 1.3rem;
    font-weight: bold;
    margin: 0.5rem 0;
    color: #333333;
}

.assignmentDescription {
    font-size: 1rem;
    margin: 0.5rem 0;
    color: #444444;
}

.scenarioContainer {
    margin: 1rem 0;
}

.studentsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 1rem;
}

.student {
    display: flex;
    align-items: center;
    gap: 0.3rem;
}

.studentAvatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid #cccccc;
}

.studentName {
    font-size: 0.9rem;
    color: #555555;
}

.teacherActions {
    display: flex;
    gap: 0.5rem;
    justify-content: flex-end;
    margin-top: 0.5rem;
}

.editButton {
    background-color: #28a745;
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
}

.deleteButton {
    background-color: #dc3545;
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
}
