.announcementsContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2rem 3rem; /* Using rem for better scalability */
    gap: 2rem; /* Adds consistent spacing between major sections */
    max-width: 1200px; /* Prevents overly wide content on large screens */
    margin: 0 auto; /* Centers the container */
}

.createAnnouncementContainer {
    background: #ffffff;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    border: 1px solid #eef2f6;
    width: 100%;
    max-width: 100%;
    display: flex; /* NEW: Makes it a flex container */
    flex-direction: column; /* NEW: Stacks children vertically */
    gap: 1rem; /* NEW: Adds spacing between TextEditor and button */
}

/* Style the submit button container */
.postAnnouncement {
    display: flex;
    justify-content: flex-end;
    width: 100%; /* Ensures it stays within container bounds */
}

.postAnnouncement button {
    background: #007bff;
    color: white;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-weight: 500;
    transition: background 0.2s ease;
}

.postAnnouncement button:hover {
    background: #0056b3;
}

.announcementsGrid {
    display: grid;
    gap: 1.5rem;
    width: 100%;
}

.announcement {
    background: #ffffff;
    border-radius: 12px;
    padding: 1.5rem;
    border: 1px solid #eef2f6;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    position: relative;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.announcement:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
}

.username {
    font-weight: 600;
    color: #1a1a1a;
    margin-bottom: 0.75rem;
    font-size: 1.1rem;
}

.announcement p {
    color: #4a4a4a;
    line-height: 1.6;
    margin: 0;
}

.announcement img,
.announcement iframe,
.announcement video {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    margin-top: 1rem;
}

.optionsButton {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.25rem;
    color: #666;
    padding: 0.5rem;
    border-radius: 50%;
    transition: background 0.2s ease;
}

.optionsButton:hover {
    background: #f5f5f5;
}

.optionsDropdown {
    position: absolute;
    top: 2.5rem;
    right: 1rem;
    background: #ffffff;
    border: 1px solid #eef2f6;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    min-width: 120px;
    overflow: hidden;
}

.optionsDropdownItem {
    padding: 0.75rem 1rem;
    cursor: pointer;
    font-size: 0.9rem;
    color: #333;
    transition: background 0.2s ease;
}

.optionsDropdownItem:hover {
    background: #f8f9fa;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .announcementsContainer {
        padding: 1rem;
    }
    
    .announcement {
        padding: 1rem;
    }
    
    .createAnnouncementContainer {
        padding: 1rem;
    }
}