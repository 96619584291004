/* Dropdown container */
.dropdown {
    position: absolute;
    top: var(--navbar-height);
    right: 0px;
    background-color: var(--background-color);
    z-index: 12;
    width: 320px;
    border-radius: 12px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.15);
    max-height: 500px;
    overflow-y: auto;
    transition: opacity 0.3s ease, transform 0.3s ease;
    animation: slideDown 0.3s ease-out;
    padding-top: 8px;
    padding-bottom: 8px;
}

/* Menu item */
.menuItem {
    display: flex;
    align-items: center;
    border-radius: 0;
    /* Removed curved corners */
    padding: 12px;
    cursor: pointer;
    background-color: var(--background-color);
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.menuItem:hover {
    background-color: #f2f2f2;
}

/* Icon container for both FontAwesome icons and profile pictures */
.iconContainer {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 15px;
    /* background-color: #e8e8e8; */
    overflow: hidden;
}

/* FontAwesome icon styling */
.icon {
    font-size: 18px;
    /* Adjust as needed */
    color: var(--primary-color);
}

/* Profile picture styling (same container dimensions as FontAwesome icon) */
.profilePicture {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

/* Text container for item labels (and optional subtitles) */
.textContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* Label text */
.label {
    font-size: 16px;
    color: #333;
}

/* Subtitle text (if needed) */
.subtitle {
    font-size: 14px;
    color: #777;
}
